<template>
    <div class="pa-4">
        <h1 class="mb-5 text--xl font-weight-medium">{{ $t('navigation.summary_of_inspections') }}</h1>
        <FiltersBar v-if="debouncedFetchInspection" ref="filtersBar"></FiltersBar>
        <InspectionsTable :loader="loader"
                          :changed-page="pagination.page"
                          :xls-loader="xlsLoader"
                          @sort="sortingColumn = $event"
                          @fetch="fetchInspection"
                          @get-file="getXLS"
                          @change-page="changePage($event)"
                          @change-amount="changeAmount($event)"></InspectionsTable>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import debounce from 'lodash.debounce';
import FiltersBar from '@/components/widgets/tables/FiltersBar';
import InspectionsTable from '@/components/inspections/InspectionsTable';
import { FILTERS, FILTERS_SEARCHING, INSPECTION_STATES } from '@/constants/filtersModelNames';
import debouncedTerritories from '@/mixins/debounced/debouncedTerritories';
import debouncedInspectors from '@/mixins/debounced/debouncedInspectors';
import debouncedCustomers from '@/mixins/debounced/debouncedCustomers';
import debouncedSites from '@/mixins/debounced/debouncedSites';
import debouncedLocations from '@/mixins/debounced/debouncedLocations';
import filtering from '@/mixins/filtering';
import debouncedTemplates from '@/mixins/debounced/debouncedTemplates';
import paginationSettings from '@/mixins/paginationSettings';
import DatePickerFilter from '@/models/filters/datePickerFilter';
import AutocompleteFilter from '@/models/filters/autocompleteFilter';
import { CONVERT_TO_UTC } from '@/helpers/dates';

export default {
    name: 'InspectionsSummary',
    components: { FiltersBar, InspectionsTable },
    mixins: [
        debouncedTerritories,
        debouncedInspectors,
        debouncedCustomers,
        debouncedSites,
        debouncedLocations,
        filtering,
        debouncedTemplates,
        paginationSettings
    ],
    data: (vm) => ({
        pagination: vm.setupPagination(),
        templatesParams: {
            'filter[current]': true
        },
        loader: false,
        debouncedFetchInspection: null,
        sortingColumn: null,
        xlsLoader: false,
        inspectionUpdater: null
    }),
    computed: {
        ...mapState('filters', [
            'filters',
            'filtersSearching'
        ]),
        ...mapFields('search', [
            'search'
        ]),
        ...mapState('templates', [
            'totalTemplatesAmount'
        ]),
        ...mapGetters('templates', [
            'filterableTemplates'
        ]),
        ...mapFields('manageInspection', [
            'detailsVisibility'
        ]),
        inspectionsStatesFilter () {
            return [
                {
                    text: this.$t('filters.inspections_states.done'),
                    value: 1
                },
                {
                    text: this.$t('filters.inspections_states.done_bad_condition'),
                    value: 2
                },
                {
                    text: this.$t('filters.inspections_states.done_error_gps'),
                    value: 3
                },
                {
                    text: this.$t('filters.inspections_states.done_wrong_date'),
                    value: 4
                },
                {
                    text: this.$t('filters.inspections_states.undone'),
                    value: 5
                },
                {
                    text: this.$t('filters.inspections_states.planned'),
                    value: 6
                }
            ];
        },
        filtersConfig () {
            return [
                new DatePickerFilter({
                    model: FILTERS.START_DATE,
                    label: this.$t('labels.date_from')
                }),
                new DatePickerFilter({
                    model: FILTERS.END_DATE,
                    label: this.$t('labels.date_to')
                }),
                new AutocompleteFilter({
                    model: FILTERS.INSPECTION_STATE,
                    label: this.$t('labels.status'),
                    items: this.inspectionsStatesFilter
                }),
                new AutocompleteFilter({
                    model: FILTERS.TERRITORY_ID,
                    label: this.$t('labels.territories'),
                    loading: true,
                    multiple: true,
                    search: FILTERS_SEARCHING.TERRITORY,
                    lazy: this.getTerritoriesLazyLoading,
                    searchCallback: this.debounceGetTerritories,
                    items: {
                        obj: 'state',
                        module: 'generalConfigurations',
                        prop: 'territories'
                    }
                }),
                new AutocompleteFilter({
                    model: FILTERS.INSPECTOR_ID,
                    label: this.$tc('labels.inspector', 2),
                    loading: true,
                    multiple: true,
                    search: FILTERS_SEARCHING.INSPECTOR,
                    lazy: this.getInspectorsLazyLoading,
                    searchCallback: this.debouncedGetInspectors,
                    items: {
                        obj: 'getters',
                        module: 'employees',
                        prop: 'inspectors'
                    }
                }),
                new AutocompleteFilter({
                    model: FILTERS.CUSTOMER_ID,
                    label: this.$t('labels.customers'),
                    loading: true,
                    multiple: true,
                    search: FILTERS_SEARCHING.CUSTOMER,
                    lazy: this.getCustomersLazyLoading,
                    referralCallback: this.referralCustomerCallback,
                    searchCallback: this.debouncedGetCustomers,
                    items: {
                        obj: 'getters',
                        module: 'customers',
                        prop: 'listingCustomers'
                    }
                }),
                new AutocompleteFilter({
                    model: FILTERS.SITE_ID,
                    label: this.$t('labels.sites'),
                    loading: false,
                    multiple: true,
                    disabled: true,
                    successMessages: this.$t('messages.choose_customer'),
                    search: FILTERS_SEARCHING.SITE_PER_CUSTOMER,
                    lazy: this.getSitesLazyLoading,
                    referralCallback: this.referralSiteCallback,
                    searchCallback: this.debouncedGetSites,
                    searchOptions: {
                        reference: FILTERS.CUSTOMER_ID
                    },
                    items: {
                        obj: 'getters',
                        module: 'sites',
                        prop: 'sites'
                    }
                }),
                new AutocompleteFilter({
                    model: FILTERS.LOCATION_ID,
                    label: this.$t('labels.locations'),
                    loading: false,
                    multiple: true,
                    disabled: true,
                    successMessages: this.$t('messages.choose_site'),
                    search: FILTERS_SEARCHING.LOCATION,
                    lazy: this.getLocationsLazyLoading,
                    searchCallback: this.debouncedGetLocations,
                    searchOptions: {
                        reference: FILTERS.SITE_ID
                    },
                    items: {
                        obj: 'getters',
                        module: 'locations',
                        prop: 'locations'
                    }
                }),
                new AutocompleteFilter({
                    model: FILTERS.TEMPLATE_ID,
                    loading: true,
                    multiple: true,
                    label: this.$tc('labels.templates', 2),
                    search: FILTERS_SEARCHING.TEMPLATE,
                    lazy: this.getTemplatesLazyLoading,
                    searchCallback: this.debounceGetTemplates,
                    items: {
                        obj: 'getters',
                        module: 'templates',
                        prop: 'filterableTemplates'
                    }
                })
            ];
        }
    },
    watch: {
        search: {
            handler (val, oldVal) {
                if ((val && val.length >= 2) || (oldVal && val === '')) {
                    this.pagination.page = 1;
                    this.debouncedFetchInspection();
                }
            }
        },
        sortingColumn: {
            handler () {
                this.fetchInspection();
            },
            deep: true
        }
    },
    methods: {
        fetchInspection (updateElements = false) {
            this.loader = !updateElements;

            if (this.pagination.page !== 1) {
                this.destroyAutoRefresh();
            } else {
                this.autoUpdateInspections();
            }

            const params = {
                'stats[total]': 'count',
                'extra_fields[inspections]': 'scan_checkpoint',
                'page[number]': this.pagination.page,
                'page[size]': this.pagination.amount,
                'filter[planned]': false,
                // here we should be able to assign our filter value
                'scan': this.$route.query.scan,
                sort: '-id',
                ...this.generateFilters()
            };

            if (this.sortingColumn) {
                params.sort = this.sortingColumn.value === 'asc' ? this.sortingColumn.name : `-${this.sortingColumn.name}`;
            }

            if (this.search) {
                params['filter[search][match]'] = this.search;
            }

            this.$store.dispatch(updateElements ? 'inspections/updateInspections' : 'inspections/getInspections', {
                params: params
            })
                .finally(() => {
                    this.loader = false;
                });
        },
        generateFilters (forXLS = false) {
            const params = {};

            this.filtersConfig.forEach(filter => {
                const f = filter.model;
                if (this.filters[f] && this.filters[f].length > 0) {
                    if (f === FILTERS.START_DATE || f === FILTERS.END_DATE) {
                        const _modifier = f === FILTERS.START_DATE ? 'after' : 'before';
                        const value = f === FILTERS.START_DATE
                            ? CONVERT_TO_UTC(`${this.filters[f]} 00:00:00`)
                            : CONVERT_TO_UTC(`${this.filters[f]} 23:59:59`);

                        if (forXLS) {
                            params[`filter[${FILTERS.INSPECTION_END_DATE}_${_modifier}]`] = value;
                        } else {
                            params[`${FILTERS.INSPECTION_END_DATE}_${_modifier}`] = value;
                        }
                    } else if (this.filters[f] && f === FILTERS.TEMPLATE_ID) {
                        params['filter[all_templates_versions]'] = this.filters[f].join(',');
                    } else if (this.filters[f] && f === FILTERS.INSPECTOR_ID && forXLS) {
                        params['filter[employee_id]'] = this.filters[f].join(',');
                    } else {
                        params[`filter[${f}]`] = this.filters[f].join(',');
                    }
                } else if (this.filters[f] && f === FILTERS.INSPECTION_STATE) {
                    const state = INSPECTION_STATES[this.filters[f]];
                    params[`filter[${state.filter}]`] = state.value;
                }
            });

            return params;
        },
        changePage (event) {
            this.pagination.page = event;
            this.fetchInspection();
        },
        changeAmount (event) {
            this.pagination.amount = event;
            this.fetchInspection();
        },
        getXLS () {
            this.xlsLoader = true;

            const params = {
                instance: 'inspections',
                prefix: 'event',
                'filter[done]': true,
                ...this.generateFilters(true)
            };

            if (this.search) {
                params['filter[search][match]'] = this.search;
            }

            return this.$store.dispatch('commonActions/getXLS', params)
                .finally(() => {
                    this.xlsLoader = false;
                });
        },
        autoUpdateInspections () {
            this.destroyAutoRefresh();
            this.inspectionUpdater = setInterval(() => {
                this.fetchInspection(true);
            }, 5000);
        },
        destroyAutoRefresh () {
            clearInterval(this.inspectionUpdater);
        }
    },
    created () {
        this.debouncedFetchInspection = debounce(this.fetchInspection, 1000);
    },
    mounted () {
        this.setFiltersWatcher(this.debouncedFetchInspection);
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            if (vm.$can(vm.$abilityActions.READ, vm.$abilitySubjects.INSPECTION)) {
                if (to.params.id) {
                    vm.$store.dispatch('manageInspection/getActiveInspection', to.params.id)
                        .then(() => {
                            vm.detailsVisibility = true;
                        });
                }
                vm.$store.commit('filters/SET_CONFIG', vm.filtersConfig);
                vm.$store.dispatch('generalConfigurations/getTerritories')
                    .then(() => {
                        vm.filtersConfig.find(el => el.model === FILTERS.TERRITORY_ID).loading = false;
                        vm.$store.commit('filters/SET_CONFIG', vm.filtersConfig);
                    });
                vm.$store.dispatch('systemSettings/getAllSettings').finally(() => {
                    vm.fetchInspection();
                });
                vm.$store.dispatch('employees/getInspectors')
                    .then(() => {
                        vm.filtersConfig.find(el => el.model === FILTERS.INSPECTOR_ID).loading = false;
                        vm.$store.commit('filters/SET_CONFIG', vm.filtersConfig);
                    });
                vm.$store.dispatch('customers/getCustomers')
                    .then(() => {
                        vm.filtersConfig.find(el => el.model === FILTERS.CUSTOMER_ID).loading = false;
                        vm.$store.commit('filters/SET_CONFIG', vm.filtersConfig);
                    });
                vm.$store.dispatch('templates/getTemplates', vm.templatesParams)
                    .then(() => {
                        vm.filtersConfig.find(el => el.model === FILTERS.TEMPLATE_ID).loading = false;
                        vm.$store.commit('filters/SET_CONFIG', vm.filtersConfig);
                    });
            } else {
                vm.fetchInspection();
            }
        });
    },
    beforeDestroy () {
        this.$store.dispatch('filters/clearFiltersParams');
        this.destroyAutoRefresh();
        this.search = null;
        this.debouncedFetchInspection = null;
        this.debounceGetTemplates = null;
        this.debouncedGetCustomers = null;
        this.debouncedGetLocations = null;
        this.debouncedGetSites = null;
        this.debouncedGetCustomers = null;
    }
};
</script>

<style scoped>

</style>
